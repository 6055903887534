import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Button, Divider, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';
import CustomCheckbox from 'components/common/CustomCheckbox';
import { tCommon } from 'constants/appConstants';
import CustomTextEditorContainer from 'components/common/CustomTextEditor';
import { FunctionalityToEdit } from 'types/settingsBlocks/settingsBlocks.api.types';
import CustomInput from 'components/common/CustomInput';
import { LoadingButton } from '@mui/lab';

const SettingsBlocksEdit = ({
    title,
    values,
    platformOptions,
    appliesToOptions,
    isLoading,
    close,
    handleChange,
    handleSubmit,
    t,
}: Props) => (
    <>
        <Grid container spacing={2} px={3} py={2} alignItems="center">
            <Grid item xs={12} md={6}>
                <Typography>{t(title)}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <CustomCheckbox
                    name="locked"
                    label={t('settings_blocks_locked_column') as string}
                    checked={!!values.locked}
                    handleChange={({ target: { name, checked } }) => handleChange(name, checked)}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography mb={0.625}>{t('settings_blocks_platform')}</Typography>

                <FormControl fullWidth size="small">
                    <Select
                        value={values.platform || ''}
                        onChange={({ target: { value } }) => handleChange('platform', value)}>
                        {platformOptions.map(option => (
                            <MenuItem key={option} value={option}>
                                {t(option)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography mb={0.625}>{t('settings_blocks_appliesTo')}</Typography>
                <FormControl fullWidth size="small">
                    <Select
                        value={values.appliesTo || ''}
                        onChange={({ target: { value } }) => handleChange('appliesTo', value)}>
                        {appliesToOptions.map(option => (
                            <MenuItem key={option} value={option}>
                                {t(option)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
                <Typography mb={0.625}>{t('settings_blocks_description')}</Typography>

                <CustomInput
                    field="description"
                    label={t('settings_blocks_description')}
                    placeholder={t('settings_blocks_description')}
                    values={{ description: values.description }}
                    handleChange={value => handleChange('description', value)}
                />
            </Grid>

            <Grid item xs={12}>
                <Divider sx={{ width: '100%', my: 1.25 }} />
                <Typography fontSize={20} mb={0.625}>
                    {t('settings_blocks_reason')}
                </Typography>
                <Typography fontSize={12} mb={0.625}>
                    {t('settings_blocks_reason_message')}
                </Typography>
                <CustomTextEditorContainer
                    value={values?.lockReason}
                    placeholder={t('settings_blocks_reason') as string}
                    onBlur={(val: string) => {
                        handleChange('lockReason', val);
                    }}
                    height={200}
                    limitChars={150}
                />
            </Grid>
            <Grid
                container
                item
                xs={12}
                spacing={2}
                display="flex"
                alignItems="center"
                justifyContent="flex-end">
                <Grid item xs={3}>
                    <Button fullWidth size="small" onClick={close} variant="outlined">
                        {t('cancel', tCommon)}
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <LoadingButton
                        fullWidth
                        size="small"
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={isLoading}
                        loading={isLoading}>
                        {t('save', tCommon)}
                    </LoadingButton>
                </Grid>
            </Grid>
        </Grid>
    </>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    title: string;
    values: FunctionalityToEdit;
    platformOptions: string[];
    appliesToOptions: string[];
    isLoading: boolean;
    close(): void;
    handleChange(name: string, value: any): void;
    handleSubmit(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SettingsBlocksEdit.propTypes = propTypes;
SettingsBlocksEdit.defaultProps = defaultProps;

export default SettingsBlocksEdit;
