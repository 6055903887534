import { withFakeData } from 'api/fakedata';
import { axiosDataInstance } from 'api/axiosInstances';
import {
    ApproveProspectRequest,
    GetClientsDefinitionsResponse,
    GetPropspectMarketDataResponse,
    GetProspectDetailResponse,
    GetProspectsDefinitionsResponse,
    GetStatesResponse,
    RejectProspectRequest,
    RelateAccountRequest,
} from 'types/clients/clients.api.types';
import { AUTH_BASE_URL, ONBOARDING_BASE_URL } from 'constants/appConstants';
import { ClientsDefinition } from 'types/clients/clients.types';
import { ProspectHistory } from 'types/users/users.api.types';

const apiName = 'clients';

export default {
    getClientsDefinitions: (filterQueryString: string) =>
        withFakeData<GetClientsDefinitionsResponse>(
            () => axiosDataInstance.get(`${AUTH_BASE_URL}/app_users/search?${filterQueryString}`),
            apiName,
            'clients',
            false,
            500,
        ),
    getProspectsDefinitions: (filterQueryString: string) =>
        withFakeData<GetProspectsDefinitionsResponse>(
            () =>
                axiosDataInstance.get(
                    `${ONBOARDING_BASE_URL}/onboarding/pending?${filterQueryString}`,
                ),
            apiName,
            'clients',
            false,
            500,
        ),
    getProspectsDetail: (id: string) =>
        withFakeData<GetProspectDetailResponse>(
            () => axiosDataInstance.get(`${ONBOARDING_BASE_URL}/onboarding/pending/${id}`),
            apiName,
            'clients',
            false,
            500,
        ),
    getClientDetail: (id: string) =>
        withFakeData<ClientsDefinition>(
            () => axiosDataInstance.get(`${AUTH_BASE_URL}/app_users/${id}`),
            apiName,
            'clients',
            false,
            500,
        ),
    enableOrDisableClient: ({ id, action }: { id: string; action: string }) =>
        withFakeData<{}>(
            () =>
                axiosDataInstance[action === 'enable' ? 'patch' : 'delete'](
                    `${AUTH_BASE_URL}/app_users/${id}/${action}/`,
                ),
            apiName,
            'clients',
            false,
            500,
        ),
    getClientPdf: (id: string) =>
        withFakeData<{}>(
            () => axiosDataInstance.get(`${AUTH_BASE_URL}/app_users/${id}/pdf_account`),
            apiName,
            'clients',
            false,
            500,
        ),
    approveProspect: (req: ApproveProspectRequest) =>
        withFakeData(
            () =>
                axiosDataInstance.post(
                    `${ONBOARDING_BASE_URL}/onboarding/step/finalize/${req.id}`,
                    req.body,
                ),
            apiName,
            'prospects',
            false,
            500,
        ),
    rejectProspect: (argument: RejectProspectRequest) =>
        withFakeData(
            () =>
                axiosDataInstance.post(
                    `${ONBOARDING_BASE_URL}/onboarding/step/reject/${argument.id}`,
                    argument.body,
                ),
            apiName,
            'prospects',
            false,
            500,
        ),
    updateProspectInformation: ({ id, body }: { id: string; body: any }) =>
        withFakeData(
            () => axiosDataInstance.patch(`${ONBOARDING_BASE_URL}/onboarding/user/${id}`, body),
            apiName,
            'clients',
            false,
            500,
        ),
    updateClientInformation: ({ id, body }: { id: string; body: any }) =>
        withFakeData(
            () => axiosDataInstance.patch(`${AUTH_BASE_URL}/app_users/user/${id}`, body),
            apiName,
            'clients',
            false,
            500,
        ),
    getProspectMarketData: (id: string) =>
        withFakeData<GetPropspectMarketDataResponse>(
            () => axiosDataInstance.get(`${ONBOARDING_BASE_URL}/onboarding/market_data/${id}`),
            apiName,
            'market-data',
            false,
            500,
        ),
    relateAcount: (req: RelateAccountRequest) =>
        withFakeData(
            () =>
                axiosDataInstance.post(
                    `${ONBOARDING_BASE_URL}/onboarding/step/relate_account/${req.id}`,
                    req.body,
                ),
            apiName,
            'relate-account',
            false,
            500,
        ),
    getStatusHistory: (id: string) =>
        withFakeData<ProspectHistory[]>(
            () => axiosDataInstance.get(`${ONBOARDING_BASE_URL}/onboarding/status/history/${id}`),
            apiName,
            'status-history',
            false,
            500,
        ),
    getStates: () =>
        withFakeData<GetStatesResponse>(
            () => axiosDataInstance.get(`${ONBOARDING_BASE_URL}/reference/states`),
            apiName,
            'states',
            false,
            500,
        ),
};
