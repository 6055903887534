import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AlertColor,
    Box,
    Button,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import CustomIconSelector from 'components/common/CustomIconSelector';
import BankAccountsDetails from 'components/common/BankAccountsDetails';
import CircularSpinner from 'components/common/CircularSpinner';
import { tModal, ICON_CELL_SIZE } from 'constants/appConstants';
import {
    ClientRiskProfile,
    ClientSectionComponent,
    ProspectStateInfo,
    InitialContactValuesToEdit,
    InitialGeneralValuesToEdit,
} from 'types/clients/clients.types';
import ClientProspectActions from '../ClientProspectActions';
import EditClientModal from '../EditClientModal';

const ClientDetail = ({
    id,
    sections,
    selectedSection,
    showBankDetails,
    isProspect,
    isLoading,
    openModalEdit,
    setOpenModalEdit,
    setShowBankDetails,
    handleChange,
    handleBackButton,
    getEditInitialValues,
    t,
    SnackBar,
    setSnackBarMessage,
    riskProfile,
    steps,
    docNumber,
    fullName,
    refetch,
    canApproveOrReject,
}: Props) => (
    <Box width="100%">
        <Box display="flex" alignItems="center" mb={1}>
            <IconButton onClick={handleBackButton}>
                <CustomIconSelector type="back" />
            </IconButton>
            <Typography fontSize={24}>
                {t(`${isProspect ? 'prospect' : 'clients'}_detail`, tModal)}
            </Typography>
        </Box>
        {isLoading ? (
            <Box mt={5} display="flex" justifyContent="center" alignItems="center">
                <CircularSpinner size={100} />
            </Box>
        ) : (
            <>
                {!isProspect && (
                    <>
                        <Box pb={2} display="flex" flexDirection="column" alignItems="end">
                            <Button
                                variant="text"
                                onClick={() => setShowBankDetails(!showBankDetails)}>
                                {t(`clients_${showBankDetails ? 'hide' : 'show'}_bank_details`)}
                            </Button>
                        </Box>
                        {showBankDetails && (
                            <BankAccountsDetails id={id} showBankDetails={showBankDetails} />
                        )}
                    </>
                )}
                {sections?.map(({ key, title, component, editable, warning }) => (
                    <Accordion
                        key={key}
                        expanded={selectedSection === key}
                        onChange={(_, expanded: boolean) => handleChange(key, expanded)}>
                        <AccordionSummary
                            sx={{ '.MuiAccordionSummary-content': { alignItems: 'center' } }}
                            expandIcon={<CustomIconSelector type="dropDown" />}>
                            <Typography mr={1}>{title}</Typography>
                            {editable && (
                                <Tooltip title={t('client_edit_tooltip')}>
                                    <IconButton
                                        onClick={event => {
                                            setOpenModalEdit({ show: true, section: key });
                                            event.stopPropagation();
                                        }}>
                                        <CustomIconSelector type="edit" sx={ICON_CELL_SIZE} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {warning && <CustomIconSelector type="alert" sx={ICON_CELL_SIZE} />}
                        </AccordionSummary>
                        <AccordionDetails>{component}</AccordionDetails>
                    </Accordion>
                ))}
            </>
        )}
        {isProspect && canApproveOrReject && (
            <ClientProspectActions
                id={id}
                docNumber={docNumber}
                fullName={fullName}
                riskProfile={riskProfile}
                steps={steps}
                refetch={refetch}
                setSnackBarMessage={setSnackBarMessage}
                handleOpenEscoInfo={handleChange}
                selectedSection={selectedSection}
            />
        )}
        <SnackBar />
        {openModalEdit.show && (
            <EditClientModal
                id={id}
                refetch={refetch}
                isProspect={isProspect}
                initialValues={getEditInitialValues()}
                openModalEdit={openModalEdit}
                setOpenModalEdit={setOpenModalEdit}
                setSnackBarMessage={setSnackBarMessage}
            />
        )}
    </Box>
);

const propTypes = {
    t: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    docNumber: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
};

interface extraProps {
    id: string;
    sections: ClientSectionComponent[];
    selectedSection: string;
    showBankDetails: boolean;
    openModalEdit: { show: boolean; section: string };
    isProspect?: boolean;
    setOpenModalEdit(openModalEdit: { show: boolean; section: string }): void;
    setShowBankDetails(showBankDetails: boolean): void;
    handleChange(selectedOption: string, expanded?: boolean): void;
    handleBackButton(): void;
    SnackBar: () => JSX.Element;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
    steps?: ProspectStateInfo[];
    riskProfile?: ClientRiskProfile;
    getEditInitialValues(): InitialGeneralValuesToEdit & InitialContactValuesToEdit;
    refetch: () => void;
    canApproveOrReject?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientDetail.propTypes = propTypes;

export default ClientDetail;
