import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ErrorPage.styled';
import consultatioLogo from 'assets/img/general/consultatioInvestmentsLogo.jpg';

const ErrorPage = ({ t, backToHome }: Props) => (
    <St.SectionWrapper component={'section'} data-testid="primary-section">
        <St.LogoContainer>
            <St.Logo src={consultatioLogo} alt="Consultatio Plus" />
        </St.LogoContainer>
        <St.Title variant="h1">{t('page_error_header')}</St.Title>
        <St.Subtitle variant="h2">{t('page_error_title')}</St.Subtitle>
        <St.Message variant="body1">{t('page_error_description')}</St.Message>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <St.HomeLink onClick={backToHome}>{t('no_permission_button_text')}</St.HomeLink>
        </div>
    </St.SectionWrapper>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    backToHome(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ErrorPage.propTypes = propTypes;

export default ErrorPage;
