import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import RegisterModal from './RegisterModal';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { tRequiredFieldError } from 'constants/appConstants';
import { useRegister } from 'hooks/api/clientImportClientVB.hooks';
import {
    RegisterFormProps,
    RegisterModalContainerProps,
} from 'types/clientImportClientVB/clientImportClientVB.types';
import { errorResponseHelper } from 'utils/helpers/errorsHelper';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = (data: { email: string; customerCode: string }) => ({
    email: data.email ?? '',
    customerCode: data.customerCode ?? '',
    welcomeEmail: true,
});

const getValidationSchema = (t: Function) =>
    Yup.object().shape({
        email: Yup.string().required(t(tRequiredFieldError)),
        customerCode: Yup.string().required(t(tRequiredFieldError)),
    });

// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const RegisterModalContainer = (props: Props) => {
    const { email, customerCode, close, setSnackBarMessage, setShowRegisterButton } = props;
    const { t } = useTranslation('clientImportClientVB');
    const { mutate, isLoading } = useRegister();

    const handleSubmit = React.useCallback(async (data: RegisterFormProps) => {
        const body = {
            customerCode: data.customerCode,
            email: data.email,
            welcomeEmail: data.welcomeEmail,
        };

        mutate(body, {
            onSuccess: () => {
                setSnackBarMessage(t('action_performed_successfully'));
                setShowRegisterButton(false);
                close();
            },
            onError(error) {
                setSnackBarMessage(errorResponseHelper(error), 'error');
            },
        });
    }, []);

    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues({ email, customerCode }),
            validateOnChange: false,
            validateOnBlur: false,
            validateOnMount: false,
            validationSchema: getValidationSchema(t),
            onSubmit: handleSubmit,
        }),
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const childProps = {
        ...props,
        formik,
        isLoading,
        t,
    };

    return <RegisterModal {...childProps} />;
};

const propTypes = {};

interface extraProps extends RegisterModalContainerProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
RegisterModalContainer.propTypes = propTypes;

export default RegisterModalContainer;
