import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Grid, Typography } from '@mui/material';
import CustomTextEditorContainer from 'components/common/CustomTextEditor';

const SettingsAlertsFormMessageEditorPage = ({ messageError, message, t, handleChange }: Props) => (
    <Grid item xs={12} maxWidth={'80%'}>
        <Typography fontSize={20} mb={0.625}>
            {t('alerts_message')}
        </Typography>
        {messageError && (
            <Typography fontSize={14} color="error" mb={1.25}>
                {messageError}
            </Typography>
        )}
        <CustomTextEditorContainer
            value={message}
            placeholder={t('alerts_editor_placeholder') as string}
            onBlur={(val: string) => {
                handleChange({ target: { name: 'message', value: val } });
            }}
            limitChars={150}
        />
    </Grid>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    message: string;
    messageError?: string;
    t(text: string, ns?: { ns: string }): string | undefined;
    handleChange(change: { target: { name: string; value: any } }): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SettingsAlertsFormMessageEditorPage.propTypes = propTypes;
SettingsAlertsFormMessageEditorPage.defaultProps = defaultProps;

export default SettingsAlertsFormMessageEditorPage;
