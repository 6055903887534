import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Route, Routes } from 'react-router-dom';
import { processRoutesRecursively } from 'utils/helpers/routesHelper';
import routes from './routeList';
import { AuthContext } from 'context/auth.context';

const AppRoutes = () => {
    const { isAuthenticated, userAbilities } = useContext(AuthContext);
    const routesMapped = React.useMemo(() => {
        const recursiveRoutes = processRoutesRecursively(routes, isAuthenticated, userAbilities);
        return recursiveRoutes;
    }, [isAuthenticated, userAbilities]);

    return (
        <>
            <Routes>{routesMapped}</Routes>
        </>
    );
};

const propTypes = {};

interface extraTypes {}

interface Props extends InferPropsExtended<typeof propTypes, extraTypes> {}

AppRoutes.propTypes = propTypes;

export default AppRoutes;
