import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import MarketDollarQuotesForm from './MarketDollarQuotesForm';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useCreateDollarQuotes, useEditDollarQuotes } from 'hooks/api/marketDollarQuotes.hooks';
import { DollarQuotes } from 'types/marketDollarQuotes/marketDollarQuotes.types';
import { tRequiredFieldError } from 'constants/appConstants';
import { TFunction } from 'i18next';

const getInitialValues = (isEdit: boolean, row: DollarQuotes) => ({
    instrument: isEdit
        ? {
              id: row.instrument.id,
              name: row.instrument.tickers[0].ticker,
              tickers: row.instrument.tickers,
          }
        : undefined,
    title: isEdit ? row.title : '',
});

const getValidationSchema = (t: TFunction<'marketDollarQuotes', undefined, 'marketDollarQuotes'>) =>
    Yup.lazy(() =>
        Yup.object().shape({
            instrument: Yup.object()
                .shape({
                    id: Yup.number(),
                    name: Yup.string(),
                })
                .required(tRequiredFieldError)
                .nullable(),
            title: Yup.string().required(tRequiredFieldError),
        }),
    );

const MarketDollarQuotesFormContainer = (props: Props) => {
    const { isEdit, row, close, setSnackBarMessage } = props;
    const { t } = useTranslation('marketDollarQuotes');

    const { mutate: onCreateDollarQuotes, isLoading: isLoadingCreate } = useCreateDollarQuotes(
        close,
        setSnackBarMessage,
    );
    const { mutate: onEditDollarQuotes, isLoading: isLoadingEdit } = useEditDollarQuotes(
        close,
        setSnackBarMessage,
    );

    const handleSubmit = React.useCallback(
        async (data: any) => {
            const payload = {
                instrumentId: data.instrument.id,
                title: data.title as string,
            };

            isEdit
                ? onEditDollarQuotes({ ...payload, id: row!.id })
                : onCreateDollarQuotes(payload);
        },
        [isEdit, onCreateDollarQuotes, onEditDollarQuotes, row],
    );

    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues(isEdit, row as DollarQuotes),
            validateOnChange: false,
            validateOnBlur: false,
            validateOnMount: false,
            validationSchema: getValidationSchema(t),
            enableReinitialize: true,
            onSubmit: handleSubmit,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const childProps = {
        ...props,
        formik,
        isLoadingSubmit: isLoadingCreate || isLoadingEdit,
        t,
    };

    return <MarketDollarQuotesForm {...childProps} />;
};

const propTypes = {
    isEdit: PropTypes.bool.isRequired,
};

const defaultProps = {
    isEdit: false,
};

interface extraProps {
    row?: DollarQuotes;
    close: () => void;
    setSnackBarMessage: (message: string) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketDollarQuotesFormContainer.propTypes = propTypes;
MarketDollarQuotesFormContainer.defaultProps = defaultProps;

export default MarketDollarQuotesFormContainer;
