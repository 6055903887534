export const ALL_USERS = 'ALL_USERS';
export const ADMIN = 'ADMIN';
export const GET_DATES = 'GET_DATES';
export const UPDATE_DATES = 'UPDATE_DATES';
export const GET_RULES = 'GET_RULES';
export const UPDATE_RULES = 'UPDATE_RULES';
export const UPDATE_INSTRUMENTS = 'UPDATE_INSTRUMENTS';
export const UPDATE_QUOTATIONS = 'UPDATE_QUOTATIONS';
export const GET_APP_USERS = 'GET_APP_USERS';
export const UPDATE_APP_USERS = 'UPDATE_APP_USERS';
export const GET_WITHDRAWALS = 'GET_WITHDRAWALS';
export const UPDATE_WITHDRAWALS = 'UPDATE_WITHDRAWALS';
export const GET_APP_USERS_BANK_ACCOUNTS = 'GET_APP_USERS_BANK_ACCOUNTS';
export const UPDATE_APP_USERS_BANK_ACCOUNTS = 'UPDATE_APP_USERS_BANK_ACCOUNTS';
export const GET_APP_USERS_REMOVE_REQUESTS = 'GET_APP_USERS_REMOVE_REQUESTS';
export const UPDATE_APP_USERS_REMOVE_REQUESTS = 'UPDATE_APP_USERS_REMOVE_REQUESTS';
export const DELETE_APP_USERS_REMOVE_REQUESTS = 'DELETE_APP_USERS_REMOVE_REQUESTS';
export const GET_DEPOSITS = 'GET_DEPOSITS';
export const GET_ALERTS = 'GET_ALERTS';
export const UPDATE_ALERTS = 'UPDATE_ALERTS';
export const GET_ORDERS_MARKET = 'GET_ORDERS_MARKET';
export const UPDATE_ORDERS_MARKET = 'UPDATE_ORDERS_MARKET';
export const DELETE_ORDERS_MARKET = 'DELETE_ORDERS_MARKET';
export const GET_ORDERS_COLLATERAL = 'GET_ORDERS_COLLATERAL';
export const UPDATE_ORDERS_COLLATERAL = 'UPDATE_ORDERS_COLLATERAL';
export const DELETE_ORDERS_COLLATERAL = 'DELETE_ORDERS_COLLATERAL';
export const GET_ORDERS_EXCHANGE = 'GET_ORDERS_EXCHANGE';
export const UPDATE_ORDERS_EXCHANGE = 'UPDATE_ORDERS_EXCHANGE';
export const DELETE_ORDERS_EXCHANGE = 'DELETE_ORDERS_EXCHANGE';
export const GET_DECLARATIONS = 'GET_DECLARATIONS';
export const UPDATE_DECLARATIONS = 'UPDATE_DECLARATIONS';
export const GET_ORDERS_FUND = 'GET_ORDERS_FUND';
export const UPDATE_ORDERS_FUND = 'UPDATE_ORDERS_FUND';
export const DELETE_ORDERS_FUND = 'DELETE_ORDERS_FUND';
export const GET_ONBOARDING = 'GET_ONBOARDING';
export const UPDATE_ONBOARDING = 'UPDATE_ONBOARDING';
export const GET_LOCKED_FUNCTIONALITY = 'GET_LOCKED_FUNCTIONALITY';
export const UPDATE_LOCKED_FUNCTIONALITY = 'UPDATE_LOCKED_FUNCTIONALITY';
export const GET_DOCUMENTS = 'GET_DOCUMENTS';

export const resourceAbilities: { [key: string]: string } = {
    MARKET_NON_WORKING_DAYS: UPDATE_DATES,
    ALERTS: UPDATE_ALERTS,
    MARKET_DOLLAR_QUOTES: UPDATE_QUOTATIONS,
    MARKET_EXTERIOR_INSTRUMENTS: UPDATE_INSTRUMENTS,
    MARKET_INSTRUMENT_RULES: UPDATE_RULES,
};
