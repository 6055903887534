import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import EditClientGeneralForm from './EditClientGeneralForm';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { tErrorsContext } from 'constants/appConstants';
import { useCallback, useMemo } from 'react';
import i18n from 'utils/i18n';
import { InitialGeneralValuesToEdit } from 'types/clients/clients.types';
import { useUpdateClientInfo, useUpdateProspectInfo } from 'hooks/api/clients.hooks';
import { AlertColor } from '@mui/material';
import { formatDate } from 'utils/helpers/dateHelper';
import { getFullName } from 'utils/helpers/commonHelper';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = ({
    name,
    lastName,
    gender,
    birthdate,
    phone_number: phoneNumber,
    email,
    referred,
}: InitialGeneralValuesToEdit) => ({
    name: name || '',
    lastName: lastName || '',
    gender: gender || '',
    birthdate: birthdate || '',
    phoneNumber: phoneNumber || '',
    email: email || '',
    referred: referred || '',
});

const getValidationSchema = () =>
    Yup.object().shape({
        email: Yup.string()
            .email(String(i18n.t('required_email', tErrorsContext)))
            .trim(),
        name: Yup.string(),
        lastName: Yup.string(),
        gender: Yup.string(),
        referred: Yup.string(),
        birthdate: Yup.string(),
    });

// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const EditClientGeneralFormContainer = (props: Props) => {
    const { close, setSnackBarMessage, refetch } = props;
    const { t } = useTranslation('clients');
    const { mutate, isLoading } = useUpdateProspectInfo(setSnackBarMessage);
    const { mutate: mutateClient, isLoading: isLoadingClient } =
        useUpdateClientInfo(setSnackBarMessage);

    const handleSubmit = useCallback(async (data: any) => {
        const birthdate = data?.birthdate ? formatDate(data?.birthdate, 'YYYY-MM-DD') : null;
        if (props.isProspect) {
            const prospectMappedData = {
                name: data?.name,
                lastName: data?.lastName,
                email: data?.email,
                phone: data?.phoneNumber,
                birthdate,
                gender: data?.gender,
                referral: data?.referred,
            };
            mutate(
                { id: props.id, body: prospectMappedData },
                {
                    onSuccess: () => {
                        setSnackBarMessage(t('prospect_edited_successfully'));
                        close();
                        refetch();
                    },
                },
            );
        } else {
            const mappedData = {
                name: data?.name,
                lastName: data?.lastName,
                email: data?.email,
                phone_number: data?.phoneNumber,
                info: {
                    birthdate,
                    gender: data?.gender,
                    referred: data?.referred,
                },
            };
            mutateClient(
                { id: props.id, body: mappedData },
                {
                    onSuccess: () => {
                        setSnackBarMessage(t('client_edited_successfully'));
                        close();
                        refetch();
                    },
                },
            );
        }
    }, []);

    const formikInitProps = useMemo(
        () => ({
            initialValues: getInitialValues(props.initialValues),
            validateOnChange: false,
            validateOnBlur: false,
            validateOnMount: false,
            validationSchema: getValidationSchema(),
            enableReinitialize: true,
            onSubmit: handleSubmit,
        }),
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const childProps = {
        ...props,
        formik,
        t,
        disabledForm:
            JSON.stringify(formik.values) ===
                JSON.stringify(getInitialValues(props.initialValues)) ||
            isLoading ||
            isLoadingClient,
        userType: props.initialValues.userType,
    };

    return <EditClientGeneralForm {...childProps} />;
};

const propTypes = {};
interface extraProps {
    id: string;
    initialValues: InitialGeneralValuesToEdit;
    isProspect?: boolean;
    isEdit?: boolean;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
    refetch: () => void;
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
EditClientGeneralFormContainer.propTypes = propTypes;

export default EditClientGeneralFormContainer;
