import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './MarketDollarQuotesForm.styled';
import { FormikProvider, Form } from 'formik';
import { Stack, Typography } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import { tCommon } from 'constants/appConstants';
import InstrumentsAutocomplete from 'components/common/InstrumentsAutocomplete';
import CustomTextEditorContainer from 'components/common/CustomTextEditor';

const MarketDollarQuotesForm = (props: Props) => {
    const { t, formik, isEdit, close, isLoadingSubmit } = props;
    const { errors, values, setFieldValue } = formik;

    return (
        <FormikProvider value={formik} data-testid="MarketDollarQuotesForm-form">
            <Form>
                <St.MarketDollarQuotesForm>
                    <Stack spacing={4}>
                        <InstrumentsAutocomplete
                            initialQueryString={'type=BOND&type=CERTIFICATE'}
                            errors={errors}
                            value={values?.instrument}
                            setFieldValue={setFieldValue}
                        />

                        <Typography fontSize={20}>{t('price_form_title_field_label')}</Typography>
                        <CustomTextEditorContainer
                            value={formik.values.title}
                            placeholder={t('proce_form_title_field_placeholder') as string}
                            onBlur={(val: string) => {
                                formik.setFieldValue('title', val);
                            }}
                            limitChars={150}
                        />
                        {formik.errors.title && <St.ErrorText>{formik.errors.title}</St.ErrorText>}

                        {Object.keys(formik.errors).length !== 0 && (
                            <St.ErrorText>
                                {t('error_message_in_market_dollar_quotes_form')}
                            </St.ErrorText>
                        )}

                        <St.ActionSectionForm direction={'row'} spacing={1}>
                            <ActionButton
                                size="medium"
                                onClick={close}
                                color="primary"
                                variant="outlined">
                                {t('cancel', tCommon)}
                            </ActionButton>
                            <ActionButton
                                size="medium"
                                type="submit"
                                color="primary"
                                variant="contained"
                                loading={isLoadingSubmit}
                                disabled={isLoadingSubmit}>
                                {isEdit
                                    ? t('dollar_quotes_edit_button')
                                    : t('dollar_quotes_create_button')}
                            </ActionButton>
                        </St.ActionSectionForm>
                    </Stack>
                </St.MarketDollarQuotesForm>
            </Form>
        </FormikProvider>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    isEdit: PropTypes.bool.isRequired,
};

const defaultProps = {};

interface extraProps {
    formik: any;
    isLoadingSubmit: boolean;
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketDollarQuotesForm.propTypes = propTypes;
MarketDollarQuotesForm.defaultProps = defaultProps;

export default MarketDollarQuotesForm;
