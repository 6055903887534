import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientSectionGeneral from './ClientSectionGeneral';
import { ClientGeneral } from 'types/clients/clients.types';
import { useTranslation } from 'react-i18next';
import { getFullName } from 'utils/helpers/commonHelper';

const ClientSectionGeneralContainer = (props: Props) => {
    const { name, fullName, lastName } = props;
    const { t } = useTranslation('clients');

    const userTitleByType = {
        APP_PHYSICAL: t('clients_general_userType_app_physical'),
        APP_LEGAL: t('clients_general_userType_app_legal'),
        CHANNEL: t('clients_general_userType_channel'),
    };

    const getFullNameDependingOnActualValues = () => fullName || getFullName({ name, lastName });

    const childProps = {
        ...props,
        userTitleByType,
        t,
        fullName: getFullNameDependingOnActualValues(),
    };

    return <ClientSectionGeneral {...childProps} />;
};

const propTypes = {};

interface extraProps extends ClientGeneral {
    rejectedPersonalInfo?: boolean;
    rejectedPersonalReason?: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientSectionGeneralContainer.propTypes = propTypes;

export default ClientSectionGeneralContainer;
