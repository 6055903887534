import { paginationAdapter } from 'adapters/common/pagination.adapter';
import {
    BusinessAssistantDefinition,
    getBusinessAssistantUsersResponse,
} from 'types/usersBusinessAssistant/usersBusinessAssistant.api.types';

export const userBusinessAssistantAdapter = (data: getBusinessAssistantUsersResponse) => {
    return {
        ...paginationAdapter(data),
        data: data.items?.map((userBusinessAssistant: BusinessAssistantDefinition) => {
            return {
                id: userBusinessAssistant.id,
                username: userBusinessAssistant.username,
                createdAt: userBusinessAssistant.createdAt,
                updatedAt: userBusinessAssistant.updatedAt,
                lastName: userBusinessAssistant.lastName,
                name: userBusinessAssistant.name,
                enabled: userBusinessAssistant.enabled,
                email: userBusinessAssistant.email,
                channelInfo: userBusinessAssistant.channelInfo,
            } as BusinessAssistantDefinition;
        }),
    };
};
