import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import UsersBusinessAssistantPage from './UsersBusinessAssistantPage';
import { useTranslation } from 'react-i18next';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { formatDateWithHour } from 'utils/helpers/dateHelper';
import { getFullName } from 'utils/helpers/commonHelper';
import {
    ActionColumn,
    ActionHeader,
    HeaderFiltersProps,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import CustomTableAction from 'components/common/CommonGrid/Extra/CustomTableAction';
import CustomIconSelector from 'components/common/CustomIconSelector';
import UsersBusinessAssistantForm from './UsersBusinessAssistantForm';
import UsersBusinessAssistantDetail from './UsersBusinessAssistantDetail';
import ClientConfirmationAction from '../ClientsPage/ClientConfirmationAction';
import { BusinessAssistantDefinition } from 'types/usersBusinessAssistant/usersBusinessAssistant.api.types';

const UsersBusinessAssistantPageContainer = (props: Props) => {
    const { t } = useTranslation('usersBusinessAssistant');

    const columns: CommonGridColumnsProps<BusinessAssistantDefinition>[] = [
        {
            id: 'createdAt',
            name: t('business_assistant_users_list_creation_date_column'),
            selector: row => formatDateWithHour(row.createdAt),
            sortField: 'createdAt',
            sortable: true,
            center: true,
        },
        {
            id: 'updatedAt',
            name: t('business_assistant_users_list_modification_date_column'),
            selector: row => formatDateWithHour(row.updatedAt),
            sortField: 'updatedAt',
            sortable: true,
            center: true,
        },
        {
            id: 'username',
            name: t('business_assistant_users_list_user_column'),
            selector: row => row.username,
            sortField: 'username',
            sortable: true,
            center: true,
        },
        {
            id: 'fullName',
            name: t('business_assistant_users_list_full_name_column'),
            selector: row => getFullName(row),
            sortField: 'fullName',
            sortable: false,
            center: true,
        },
        {
            id: 'status',
            name: t('business_assistant_users_list_status_column'),
            selector: row =>
                t(
                    `business_assistant_users_list_${row.enabled ? 'enabled' : 'disabled'}`,
                ) as string,
            sortField: 'enabled',
            sortable: true,
            center: true,
        },
    ];

    const headerActions: ActionHeader[] = [
        {
            id: 'create',
            component: () => (
                <CustomTableAction
                    type="create"
                    Icon={props => <></>}
                    modalTitle="business_assistant_user"
                    component={(props: any) => <UsersBusinessAssistantForm {...props} />}
                />
            ),
        },
    ];

    const actionColumns: ActionColumn[] = [
        {
            id: 'detail',
            icon: (props: any) => <CustomIconSelector type="detail" sx={props.sx} />,
            component: (props: any) => <UsersBusinessAssistantDetail {...props} />,
        },
        {
            id: 'edit',
            icon: (props: any) => <CustomIconSelector type="edit" sx={props.sx} />,
            component: (props: any) => <UsersBusinessAssistantForm isEdit {...props} />,
        },
        {
            id: 'disable',
            icon: (props: any) => <CustomIconSelector type="disable" sx={props.sx} />,
            isHidden: (row: any) => !row?.enabled,
            component: (props: any) => <ClientConfirmationAction {...props} action="disable" />,
        },
        {
            id: 'enable',
            icon: (props: any) => <CustomIconSelector type="enable" sx={props.sx} />,
            isHidden: (row: any) => row?.enabled,
            component: (props: any) => <ClientConfirmationAction {...props} action="enable" />,
        },
    ];

    const headerFilters: HeaderFiltersProps[] = [
        {
            id: 'name',
            label: t('business_assistant_users_name'),
            type: 'text',
            placeholder: t('business_assistant_users_list_name_filter_placeholder'),
            query: 'fullName',
        },
        {
            id: 'createdAtFrom',
            label: t('business_assistant_users_list_create_day_from_filter_label'),
            type: 'date',
            placeholder: t('business_assistant_users_list_create_day_from_filter_placeholder'),
            query: 'fromCreatedAt',
        },
        {
            id: 'createdAtTo',
            label: t('business_assistant_users_list_create_day_to_filter_label'),
            type: 'date',
            placeholder: t('business_assistant_users_list_create_day_to_filter_placeholder'),
            query: 'toCreatedAt',
        },
        {
            id: 'updatedAtFrom',
            label: t('business_assistant_users_list_updated_day_from_filter_label'),
            type: 'date',
            placeholder: t('business_assistant_users_list_updated_day_from_filter_placeholder'),
            query: 'fromUpdatedAt',
        },
        {
            id: 'updatedAtTo',
            label: t('business_assistant_users_list_update_day_to_filter_label'),
            type: 'date',
            placeholder: t('business_assistant_users_list_update_day_to_filter_placeholder'),
            query: 'toUpdatedAt',
        },
        {
            id: 'uuid',
            label: t('business_assistant_users_list_ref_filter_label'),
            type: 'text',
            placeholder: t('business_assistant_users_list_ref_filter_placeholder'),
            query: 'uuids',
        },
        {
            id: 'user',
            label: t('business_assistant_users_username'),
            type: 'text',
            placeholder: t('business_assistant_users_list_user_filter_placeholder'),
            query: 'username',
        },
        {
            id: 'status',
            label: t('business_assistant_users_list_status_filter_label'),
            type: 'select',
            placeholder: t('business_assistant_users_list_status_filter_placeholder'),
            query: 'enabled',
            options: [
                { id: 'true', name: t('business_assistant_users_list_enabled') },
                { id: 'false', name: t('business_assistant_users_list_disabled') },
            ],
        },
    ];

    const childProps = {
        ...props,
        t,
        columns,
        headerActions,
        actionColumns,
        headerFilters,
    };

    return <UsersBusinessAssistantPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UsersBusinessAssistantPageContainer.propTypes = propTypes;

export default UsersBusinessAssistantPageContainer;
