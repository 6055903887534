import React, { useState, useEffect } from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientDetail from './ClientDetail';
import { useTranslation } from 'react-i18next';
import {
    GENERAL,
    ADDRESS,
    EXTRAINFO,
    TAX_STATUS,
    DOCUMENTS,
    ACTIVITY,
    ACCOUNTS,
    PROSPECTS_STEPS,
} from 'constants/appConstants';
import ClientSectionActivity from '../ClientInfoSections/ClientSectionActivity';
import ClientSectionAddress from '../ClientInfoSections/ClientSectionAddress';
import ClientSectionDocuments from '../ClientInfoSections/ClientSectionDocuments';
import ClientSectionExtraInfo from '../ClientInfoSections/ClientSectionExtraInfo';
import ClientSectionGeneral from '../ClientInfoSections/ClientSectionGeneral';
import ClientSectionTaxSituation from '../ClientInfoSections/ClientSectionTaxSituation';
import ClientSectionCustomerCodes from '../ClientInfoSections/ClientSectionCustomerCodes';
import ClientSectionProspectSteps from '../ClientInfoSections/ClientSectionProspectSteps';
import {
    useGetClientDetail,
    useGetProspectDetail,
    useGetStatusHistory,
} from 'hooks/api/clients.hooks';
import { prospectInfoAdapter } from 'adapters/api/clients.adapter';
import { useLocation, useNavigate } from 'react-router-dom';
import useSnackBar from 'hooks/common/snackbar.hooks';
import {
    ClientsDefinition,
    ClientSectionComponent,
    ProspectStateInfo,
} from 'types/clients/clients.types';
import { getFullName, hasStepCompleted } from 'utils/helpers/commonHelper';
import { useGetActionsReestrictions } from 'hooks/common/roles.hooks';
import { UPDATE_APP_USERS, UPDATE_ONBOARDING } from 'constants/abilities.constants';

const ClientDetailContainer = ({ isProspect }: Props) => {
    const { t } = useTranslation('clients');

    const [selectedSection, setSelectedSection] = useState('');
    const [showBankDetails, setShowBankDetails] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState({ show: false, section: '' } as {
        show: boolean;
        section: string;
    });
    const [forceRefresh, setForceRefresh] = useState(false);
    const [sections, setSections] = useState([] as ClientSectionComponent[]);
    const [clientOrProspectInfo, setClientOrProspectInfo] = useState({} as ClientsDefinition);
    const { getProspectDetail, isLoading: isLoadingProspect } = useGetProspectDetail();
    const { getClientDetail, isLoading: isLoadingClient } = useGetClientDetail();
    const { data: clientHistory, mutateAsync } = useGetStatusHistory();
    const { state } = useLocation();
    const navigate = useNavigate();
    const { SnackBar, setSnackBarMessage } = useSnackBar();
    const { isHiddenByAbility } = useGetActionsReestrictions();

    const validateEditableByHiddenAbility = !(
        isHiddenByAbility(UPDATE_APP_USERS) || isHiddenByAbility(UPDATE_ONBOARDING)
    );

    const validateEditableProspect = (typeStep: string, steps?: ProspectStateInfo[]) =>
        validateEditableByHiddenAbility && steps && hasStepCompleted(steps, typeStep);

    const getHistory = () => mutateAsync(state?.id || localStorage.getItem('clientId'));
    const getSectionInfo = () => {
        const {
            id,
            email,
            username,
            fullName,
            userType,
            lastLogin,
            info,
            updatedAt,
            contacts,
            documents,
            marketAccounts,
            economicData,
            prospectHistory,
            steps,
            name,
            lastName,
            phone_number: phoneNumber,
            riskProfile,
            rejectedPersonalInfo,
            rejectedPersonalReason,
        } = clientOrProspectInfo;

        const sectionGeneralInfo: { [key: string]: any } = {
            [ACCOUNTS]: marketAccounts,
            [GENERAL]: {
                id,
                email,
                username,
                fullName,
                name,
                lastName,
                userType,
                lastLogin,
                gender: info?.gender,
                termsAndConditions: info?.termsAndConditions,
                docNumber: info?.docNumber,
                docType: info?.docType,
                referred: info?.referred,
                nationality: info?.nationality,
                updatedAt,
                birthdate: info?.birthdate,
                countryTaxResidence: info?.countryTaxResidence,
                phoneNumber,
                rejectedPersonalInfo,
                rejectedPersonalReason,
            },
            [ADDRESS]: contacts,
            [EXTRAINFO]: {
                PEP: info?.PEP,
                isUSA: info?.isUSA,
                ssn: info?.ssn,
                countryTaxResidence: info?.countryTaxResidence,
                OCDE: info?.OCDE,
                isUIF: info?.isUIF,
                investmentProfile: info?.investmentProfile,
                investorProfileExpirationDate: info?.investorProfileExpirationDate,
                economicData,
                pepCharge: info?.pepCharge,
            },
            [TAX_STATUS]: {
                taxCondition: info?.taxCondition,
                earningRegistration: info?.earningsRegistration,
            },
            [DOCUMENTS]: documents,
            [ACTIVITY]: prospectHistory,
            [PROSPECTS_STEPS]: {
                steps,
                riskProfile,
            },
        };

        const sectionsOnlyPropsect = [
            {
                key: PROSPECTS_STEPS,
                title: t('clients_section_prospects_steps'),
                component: <ClientSectionProspectSteps {...sectionGeneralInfo[PROSPECTS_STEPS]} />,
            },
        ];

        const sectionsOnlyClient = [
            {
                key: ACCOUNTS,
                title: t('clients_section_accounts'),
                component: (
                    <ClientSectionCustomerCodes marketAccounts={sectionGeneralInfo[ACCOUNTS]} />
                ),
            },
        ];

        const sectionsGeneral = [
            {
                key: GENERAL,
                title: t('clients_section_general'),
                component: <ClientSectionGeneral {...sectionGeneralInfo[GENERAL]} />,
                editable: isProspect
                    ? !!info && validateEditableProspect('PERSONAL_DATA', steps)
                    : validateEditableByHiddenAbility,
                warning: rejectedPersonalInfo,
            },
            {
                key: ADDRESS,
                title: t('clients_section_address'),
                component: <ClientSectionAddress contacts={sectionGeneralInfo[ADDRESS]} />,
                editable: isProspect
                    ? contacts.length !== 0 && validateEditableProspect('CONTACT_INFO', steps)
                    : validateEditableByHiddenAbility,
            },
            {
                key: EXTRAINFO,
                title: t('clients_section_extraInfo'),
                component: (
                    <ClientSectionExtraInfo
                        {...sectionGeneralInfo[EXTRAINFO]}
                        isProspect={isProspect}
                    />
                ),
            },
            {
                key: TAX_STATUS,
                title: t('clients_section_taxStatus'),
                component: <ClientSectionTaxSituation {...sectionGeneralInfo[TAX_STATUS]} />,
            },
            {
                key: DOCUMENTS,
                title: t('clients_section_documents'),
                component: <ClientSectionDocuments documents={sectionGeneralInfo[DOCUMENTS]} />,
            },
            {
                key: ACTIVITY,
                title: t('clients_section_activity'),
                component: (
                    <ClientSectionActivity
                        prospectHistory={
                            isProspect ? sectionGeneralInfo[ACTIVITY] : clientHistory?.data
                        }
                    />
                ),
            },
        ];
        setSections(sectionsGeneral.concat(isProspect ? sectionsOnlyPropsect : sectionsOnlyClient));
    };

    const getEditInitialValues = () => {
        const {
            name,
            lastName,
            email,
            contacts,
            info,
            phone_number: phoneNumber,
            userType,
        } = clientOrProspectInfo;

        return {
            name,
            lastName,
            email,
            phone_number: phoneNumber,
            gender: info?.gender,
            birthdate: info?.birthdate,
            streetName: contacts ? contacts[0]?.streetName : '',
            streetNum: contacts ? contacts[0]?.streetNum : '',
            addressExtra: contacts ? contacts[0]?.addressExtra : '',
            floor: contacts ? contacts[0]?.floor : '',
            square: contacts ? contacts[0]?.square : '',
            apartment: contacts ? contacts[0]?.apartment : '',
            tower: contacts ? contacts[0]?.tower : '',
            sector: contacts ? contacts[0]?.sector : '',
            zipCode: contacts ? contacts[0]?.zipCode : '',
            city: contacts ? contacts[0]?.city : '',
            state: contacts ? contacts[0]?.state : '',
            country: contacts ? contacts[0]?.country : '',
            contactId: contacts ? contacts[0]?.id : '',
            referred: info?.referred || '',
            userType,
        };
    };

    const handleGetData = () => {
        const id = state?.id ?? localStorage.getItem('clientId');
        isProspect
            ? getProspectDetail(id, {
                  onSuccess(data) {
                      setClientOrProspectInfo(prospectInfoAdapter(data.data));
                  },
              })
            : getClientDetail(id, {
                  onSuccess(data) {
                      setClientOrProspectInfo(data.data);
                  },
              });
    };

    useEffect(() => {
        if (Object.keys(clientOrProspectInfo).length > 0) getSectionInfo();
    }, [clientOrProspectInfo]);

    useEffect(() => {
        if (state?.id || localStorage.getItem('clientId')) handleGetData();
        if (!isProspect) getHistory();
        return () => {
            localStorage.removeItem('clientId');
        };
    }, []);

    useEffect(() => {
        if (forceRefresh) {
            handleGetData();
            setForceRefresh(false);
        }
    }, [forceRefresh]);

    const handleChange = (section: string, expanded: boolean) =>
        setSelectedSection(expanded ? section : '');

    const handleBackButton = () => navigate(-1);

    const childProps = {
        id: clientOrProspectInfo.id,
        isProspect,
        selectedSection,
        sections,
        showBankDetails,
        isLoading: isLoadingProspect || isLoadingClient,
        openModalEdit,
        setOpenModalEdit,
        setShowBankDetails,
        handleChange,
        handleBackButton,
        getEditInitialValues,
        t,
        SnackBar,
        setSnackBarMessage,
        steps: clientOrProspectInfo?.steps,
        canApproveOrReject:
            clientOrProspectInfo?.status === 'COMPLETED' &&
            !clientOrProspectInfo?.pendingFinalization,
        riskProfile: clientOrProspectInfo?.riskProfile,
        docNumber: clientOrProspectInfo.info?.docNumber ?? '-',
        fullName:
            !clientOrProspectInfo?.name || !clientOrProspectInfo?.lastName
                ? clientOrProspectInfo.fullName
                : getFullName(clientOrProspectInfo),
        refetch: () => setForceRefresh(true),
    };

    return <ClientDetail {...childProps} />;
};

const propTypes = {};

interface extraProps {
    isProspect?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientDetailContainer.propTypes = propTypes;

export default ClientDetailContainer;
