import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Form, FormikProvider } from 'formik';
import { tCommon } from 'constants/appConstants';
import { Button, Grid } from '@mui/material';
import CustomInput from 'components/common/CustomInput';
import UsersBusinessAssistantReferralList from '../UsersBusinessAssistantReferralList';

const UsersBusinessAssistantForm = (props: Props) => {
    const { formik, t, isEdit, disabledForm, close } = props;
    const { errors, values, setFieldValue } = formik;

    return (
        <FormikProvider value={formik} data-testid="UsersBusinessAssistantForm-form">
            <Form>
                <Grid container spacing={2} px={3} py={2}>
                    <Grid item xs={12} md={6}>
                        <CustomInput
                            required
                            field="name"
                            label={t('business_assistant_users_name')}
                            placeholder={t('business_assistant_users_name')}
                            values={values}
                            errors={errors}
                            setFieldValue={setFieldValue}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomInput
                            required
                            field="lastName"
                            label={t('business_assistant_users_lastName')}
                            placeholder={t('business_assistant_users_lastName')}
                            values={values}
                            errors={errors}
                            setFieldValue={setFieldValue}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <CustomInput
                            required
                            field="username"
                            label={t('business_assistant_users_username')}
                            placeholder={t('business_assistant_users_username')}
                            values={values}
                            errors={errors}
                            setFieldValue={setFieldValue}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <CustomInput
                            required
                            field="email"
                            label={t('business_assistant_users_email')}
                            placeholder={t('business_assistant_users_email')}
                            values={values}
                            errors={errors}
                            setFieldValue={setFieldValue}
                        />
                    </Grid>

                    {!isEdit && (
                        <>
                            <Grid item xs={12} md={6}>
                                <CustomInput
                                    required
                                    inputType="password"
                                    field="password"
                                    label={t('business_assistant_users_password')}
                                    placeholder={t('business_assistant_users_password')}
                                    values={values}
                                    errors={errors}
                                    setFieldValue={setFieldValue}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <CustomInput
                                    required
                                    inputType="password"
                                    field="passwordConfirmation"
                                    label={t('business_assistant_users_confirmation_password')}
                                    placeholder={t(
                                        'business_assistant_users_confirmation_password',
                                    )}
                                    values={values}
                                    errors={errors}
                                    setFieldValue={setFieldValue}
                                />
                            </Grid>
                        </>
                    )}

                    <UsersBusinessAssistantReferralList
                        value={values.referralCodes}
                        setFieldValue={setFieldValue}
                        error={errors.referralCodes}
                    />

                    <Grid item container xs={12} spacing={2} justifyContent="end">
                        <Grid item xs={3}>
                            <Button fullWidth size="small" onClick={close} variant="outlined">
                                {t('cancel', tCommon)}
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                type="submit"
                                fullWidth
                                size="small"
                                disabled={disabledForm}
                                variant="contained">
                                {isEdit ? t('edit', tCommon) : t('add', tCommon)}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Form>
        </FormikProvider>
    );
};

const propTypes = {
    isEdit: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    disabledForm: PropTypes.bool.isRequired,
};

interface extraProps {
    formik: any;
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UsersBusinessAssistantForm.propTypes = propTypes;

export default UsersBusinessAssistantForm;
