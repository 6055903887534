import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import UsersBusinessAssistantDetail from './UsersBusinessAssistantDetail';
import { useTranslation } from 'react-i18next';
import { BusinessAssistantDefinition } from 'types/usersBusinessAssistant/usersBusinessAssistant.api.types';

const UsersBusinessAssistantDetailContainer = (props: Props) => {
    const { t } = useTranslation('usersBusinessAssistant');

    const childProps = {
        ...props,
        t,
    };

    return <UsersBusinessAssistantDetail {...childProps} />;
};

const propTypes = {};

interface extraProps {
    row: BusinessAssistantDefinition;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UsersBusinessAssistantDetailContainer.propTypes = propTypes;

export default UsersBusinessAssistantDetailContainer;
