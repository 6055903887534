import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Grid } from '@mui/material';
import ChannelsModalItemGrid from 'components/common/ChannelsModalItemGrid';
import { BusinessAssistantDefinition } from 'types/usersBusinessAssistant/usersBusinessAssistant.api.types';

const UsersBusinessAssistantDetail = ({
    row: { name, lastName, username, email, channelInfo },
    t,
}: Props) => (
    <Grid container spacing={2} px={3} py={2}>
        <Grid container item xs={12} md={12}>
            <ChannelsModalItemGrid label={t('business_assistant_users_name')} text={name} />
            <ChannelsModalItemGrid label={t('business_assistant_users_lastName')} text={lastName} />
            <ChannelsModalItemGrid label={t('business_assistant_users_email')} text={email} />
            <ChannelsModalItemGrid label={t('business_assistant_users_username')} text={username} />
            {channelInfo.length > 0 && (
                <ChannelsModalItemGrid
                    label={t('referral_codes_title')}
                    text={channelInfo.map(({ referralCode }) => referralCode).join(', ')}
                />
            )}
        </Grid>
    </Grid>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    row: BusinessAssistantDefinition;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UsersBusinessAssistantDetail.propTypes = propTypes;

export default UsersBusinessAssistantDetail;
