import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './EditClientGeneralForm.styled';
import { Form, FormikProvider } from 'formik';
import ActionButton from 'components/common/ActionButton';
import { GENDERS, tCommon } from 'constants/appConstants';
import { Alert, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import CustomInput from 'components/common/CustomInput';
import CustomDatePicker from 'components/common/CustomDatePicker';

const EditClientGeneralForm = ({
    formik,
    userType,
    t,
    isEdit,
    disabledForm,
    isProspect,
    close,
}: Props) => (
    <FormikProvider value={formik} data-testid="EditClientGeneralForm-form">
        <Form>
            <Grid container spacing={2} py={1.25}>
                <Grid item xs={userType && userType !== 'APP_LEGAL' ? 6 : 12}>
                    <CustomInput
                        field="name"
                        label={t('name', tCommon)}
                        placeholder={t('enter_name', tCommon)}
                        values={formik.values}
                        errors={formik.errors}
                        setFieldValue={formik.setFieldValue}
                    />
                </Grid>
                {userType && userType !== 'APP_LEGAL' && (
                    <Grid item xs={6}>
                        <CustomInput
                            field="lastName"
                            label={t('surname', tCommon)}
                            placeholder={t('enter_surname', tCommon)}
                            values={formik.values}
                            errors={formik.errors}
                            setFieldValue={formik.setFieldValue}
                        />
                    </Grid>
                )}
                <Grid item xs={6}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="label-gender">{t('clients_general_gender')}</InputLabel>
                        <Select
                            labelId="label-gender"
                            value={formik.values.gender}
                            label={t('clients_general_gender')}
                            onChange={({ target: { value } }) =>
                                formik.setFieldValue('gender', value)
                            }>
                            {GENDERS.map(({ label, value }) => (
                                <MenuItem key={label} value={value}>
                                    {t(`clients_general_gender_${label}`)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <CustomDatePicker
                        fieldValue="birthdate"
                        placeholder={t('clients_general_birthdate')}
                        value={formik.values.birthdate}
                        setFieldValue={formik.setFieldValue}
                        errorMessage={formik.errors.birthdate}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomInput
                        field="phoneNumber"
                        label={t('clients_general_phoneNumber')}
                        placeholder={t('clients_general_phoneNumber_placeholder')}
                        values={formik.values}
                        errors={formik.errors}
                        setFieldValue={formik.setFieldValue}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomInput
                        field="email"
                        label={t('email', tCommon)}
                        placeholder={t('enter_email', tCommon)}
                        values={formik.values}
                        errors={formik.errors}
                        setFieldValue={formik.setFieldValue}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomInput
                        field="referred"
                        label={t('referred', tCommon)}
                        placeholder={t('enter_referred', tCommon)}
                        values={formik.values}
                        errors={formik.errors}
                        setFieldValue={formik.setFieldValue}
                    />
                </Grid>
            </Grid>
            {!isProspect && (
                <Alert severity="warning" sx={{ my: 2 }}>
                    {t('esco_warning')}
                </Alert>
            )}

            <St.ActionSectionForm direction="row" spacing={1}>
                <ActionButton onClick={close} variant="outlined">
                    {t('cancel', tCommon)}
                </ActionButton>
                <ActionButton
                    size="medium"
                    type="submit"
                    disabled={isEdit ? disabledForm : false}
                    color="primary"
                    variant="contained">
                    {t(isEdit ? 'edit' : 'add', tCommon)}
                </ActionButton>
            </St.ActionSectionForm>
        </Form>
    </FormikProvider>
);

const propTypes = {
    t: PropTypes.func.isRequired,
    disabledForm: PropTypes.bool.isRequired,
};

interface extraProps {
    formik: any;
    close: () => void;
    isProspect?: boolean;
    userType?: string;
    isEdit?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
EditClientGeneralForm.propTypes = propTypes;

export default EditClientGeneralForm;
