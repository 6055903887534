import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './CustomInput.styled';
import { FormikErrors, FormikValues } from 'formik';
import { OverridableStringUnion } from '@mui/types';
const CustomInput = (props: Props) => {
    const {
        field,
        values,
        noError,
        errors,
        autoFocus,
        label,
        required,
        inputType,
        placeholder,
        size,
        maxLength,
        disabled,
        inputProps,
        helperText,
        onChangeInput,
        style,
        multiline,
        rows,
    } = props;

    return (
        <St.TextField
            autoFocus={autoFocus}
            fullWidth
            id={`${field}`}
            label={label && `${label}${required ? ' * ' : ''}`}
            type={inputType ?? 'text'}
            value={values[field]}
            onChange={({ target }: any) => {
                onChangeInput(target.value);
            }}
            ownerState={{
                disabled,
            }}
            error={!noError && errors && errors[field] !== undefined}
            helperText={(!noError && errors && errors[field]?.toString()) ?? helperText}
            placeholder={placeholder ?? undefined}
            size={size}
            variant="outlined"
            inputProps={{
                ...inputProps,
                autoComplete: inputProps?.autoComplete ? inputProps?.autoComplete : 'one-time-code',
                maxLength: maxLength ?? '',
                style: { ...style },
            }}
            multiline={multiline}
            rows={rows}
            disabled={disabled}
        />
    );
};

const propTypes = {
    field: PropTypes.string.isRequired,
    noError: PropTypes.bool.isRequired,
    onChangeInput: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    label: PropTypes.string,
    maxLength: PropTypes.number,
    required: PropTypes.bool,
    style: PropTypes.any,
    inputProps: PropTypes.any,
    placeholder: PropTypes.string,
    inputType: PropTypes.string,
    helperText: PropTypes.string,
};

const defaultProps = {
    autoFocus: false,
    disabled: false,
    noError: false,
    size: 'small',
};

interface extraProps {
    regex?: RegExp;
    values: FormikValues;
    errors?: FormikErrors<FormikValues>;
    size: OverridableStringUnion<'small' | 'medium', ''>;
    multiline?: boolean;
    rows?: number;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomInput.propTypes = propTypes;
CustomInput.defaultProps = defaultProps;

export default CustomInput;
